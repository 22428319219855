import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withLazyImport from '~spa/Components/LazyLoad/withLazyImport';
import { navigateTo } from '~spa/Utils/NavigationAdapter';
import {
    useStateInitialized,
} from '~SpectateStore/hooks/clientStateHooks';
import {
    useGeoCheckStatusDispatch,
    useUserBalanceDispatch,
} from '~SpectateStore/hooks/userHooks';
import { getWsInterface } from './Utils/Websockets';
import {
    clientInitFailed,
    onOpenMyBetsRequest,
    onOddsFormatUpdate,
    onUserAuthenticate,
    onUserLogout,
    onDeactivate,
    onNavigate,
    onNavigateByCode,
    onPlayerSegmentationUpdate,
    subscribedToMessageBroker,
    onAddToBetslip,
    onFavouriteUpdate,
    onCustomerThemeModeUpdate,
    onGeoLocationStatusUpdate,
    onUserBalanceUpdate,
    onPostRegistrationDepositClosed,
    onClientStateChanged,
} from './Utils/WrapperIntegration/Ucf';
import {
    processClientSettings,
} from './Utils/WrapperIntegration/ClientSettings';
import {
    authenticate,
    logout,
} from './Utils/Authentication';
import {
    updateOddsFormat,
} from './Utils/OddsFormat';
import {
    setFavouriteSports,
    setDidReceivePRDCMessage,
} from '~shared/utils/user';
import {
    exitFullScreenBetslip,
    openBetslipMyBetsTab,
} from '~Betslip/interface/interactions';
import {
    changeSingleFavouriteSport,
    publishFavSports,
    publishFavSportsError,
} from '~shared/utils/favourite-sports';
import { useSpectateContext } from '~shared/utils/hooks';
import i18n from '~shared/utils/i18n';
import { updateThemeMode } from './Utils/ThemeMode';
import Heartbeat from '~spa/Components/Heartbeat';
import { initializeClickstream } from '~shared/services/clickstream';
import { useClientInactivity } from '~shared/hooks/useClientInactivity';

const Spa = withLazyImport(
    () => import(/* webpackChunkName: "spa" */ './Spa')
);

const SpectateClient = ({ init }) => {
    const context = useSpectateContext();
    const [isUiMode, setIsUiMode] = useState(null);
    const [pageVisibility, setPageVisibility] = useState('foreground');
    const {
        isSuccess: stateInitIsSuccess,
        errorMessage: stateInitErrorMessage,
    } = useStateInitialized();
    const updateUserBalance = useUserBalanceDispatch();
    const updateGeoCheckStatus = useGeoCheckStatusDispatch();

    useClientInactivity(isUiMode, setIsUiMode, pageVisibility);

    useEffect(() => {
        if (init.active) {
            navigateTo(init.navigation);
            setIsUiMode(true);
        }
    }, []);

    useEffect(() => {
        if (stateInitIsSuccess === null) {
            return;
        }

        if (stateInitIsSuccess === false) {
            clientInitFailed(stateInitErrorMessage);

            return;
        }

        initializeClickstream();

        onUserBalanceUpdate((data) => {
            const {
                Balance: {
                    AvailableOnBankrollCurrency: balance = null,
                },
            } = data;
            updateUserBalance(balance);
        });

        onUserAuthenticate((data) => authenticate(data));

        onUserLogout((data) => logout(data));

        onNavigate(({ uri }) => {
            exitFullScreenBetslip();
            navigateTo(uri);
            setIsUiMode(true);
        });

        onNavigateByCode((_, envelope) => {
            const {
                headers: {
                    publisher,
                },
            } = envelope;

            // Close Betslip on navigation triggered by Native App
            if (publisher === 'Native') {
                exitFullScreenBetslip();
            }
        });

        onAddToBetslip(({ betSlipData }) => {
            const { selectionIds, surveyId } = betSlipData;
            let uri = `/deeplink?selections=[${selectionIds.join(',')}]`; // Mock Deeplink URI
            if (surveyId) {
                uri += `&surveyId=${surveyId}`;
            }
            navigateTo(uri);
            setIsUiMode(true);
        });

        onDeactivate(() => setIsUiMode(false));

        onPlayerSegmentationUpdate(
            (playerSegmentation) => {
                const {
                    isFTD,
                    isPromotionPermitted,
                    isPromotionRestricted,
                    isVIP,
                    RMS,
                    serial,
                } = playerSegmentation;
                processClientSettings({
                    isFTD,
                    isPromotionPermitted,
                    isPromotionRestricted,
                    isVIP,
                    RMS,
                    serial,
                });
            }
        );

        onOddsFormatUpdate((oddsFormat) => updateOddsFormat(oddsFormat));

        onOpenMyBetsRequest(() => openBetslipMyBetsTab());

        onCustomerThemeModeUpdate(({themeMode}) => updateThemeMode(themeMode));

        publishFavSports();

        // this will be called when the user selects a favourite sport from the left nav menu in the wapper.
        onFavouriteUpdate((sportData) => {
            changeSingleFavouriteSport(sportData)
                .then((favSportsIds) => {
                    setFavouriteSports(favSportsIds);
                    publishFavSports();
                })
                .catch((error) => {
                    const { status } = error;
                    const errorMsg = (status === 409)
                        ? 'favourite_sports.error_too_many_sports'
                        : 'favourite_sports.error_generic';

                    publishFavSportsError(i18n.t(errorMsg));
                });
        });

        onGeoLocationStatusUpdate(({status}) => updateGeoCheckStatus(status));

        onPostRegistrationDepositClosed(() => setDidReceivePRDCMessage(true));

        onClientStateChanged(({ clientStateName }) => setPageVisibility(clientStateName.toLowerCase()));

        subscribedToMessageBroker();
    }, [stateInitIsSuccess]);

    useEffect(() => {
        if (isUiMode === false) {
            getWsInterface().closeAllConnections();
            exitFullScreenBetslip();
        }
    }, [isUiMode]);

    const showSpa = isUiMode && context && stateInitIsSuccess;

    return (
        <>
            {stateInitIsSuccess && <Heartbeat />}
            {showSpa && <Spa />}
        </>
    );
};

SpectateClient.propTypes = {
    init: PropTypes.object.isRequired,
};

export default SpectateClient;
