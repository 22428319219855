import { getSpaContainerId } from '~spa/Utils/Spectate.js';

/**
 * Scrolls the window to the top
 * @return {void}
 */
export function scrollToTop() {
    const xCoordinate = 0;
    const yCoordinate = 0;
    const body = document.querySelector('body');
    const appRoot = document.getElementById(getSpaContainerId());
    const appRootTop = Math.abs(appRoot.getBoundingClientRect().top);

    window.scrollTo(xCoordinate, yCoordinate);
    body.scrollTo(xCoordinate, yCoordinate);
    appRoot.style.scrollMarginTop = `${appRootTop}px`;
    appRoot.scrollIntoView();
    appRoot.style.removeProperty('scroll-margin-top');
}

/**
 * Returns the browser history location pathname.
 * @returns {String} The pathname
 */
export function getBrowserHistoryPathname() {
    return window.location.pathname;
}

/**
 * Returns the localtion origin
 * @return {String} The origin
 */
export function getOrigin() {
    return window.location.origin;
}

/**
 * Go back
 * @return {void}
 */
export function navigateBack() {
    window.history.back();
}

/**
 * Returns the URI of the page that linked to the currently visited page.
 * If the user navigated to the page directly (i.e., not through a link), it will return an empty string.
 * @returns {string} - The referrer URI or an empty string if there isn't one.
 */
export const getDocumentReferer = () => document.referrer || '';

/**
 * Checks if the element is fully visible
 * @param  {Element} element The element
 * @return {Boolean} A boolean value indicating if the element is fully visible or not
 */
export function elementIsFullyVisible(element) {
    const box = element.getBoundingClientRect();
    const startPosition = 0;

    return !(
        box.bottom < startPosition ||
        box.right < startPosition ||
        box.top < startPosition ||
        box.left < startPosition ||
        (box.left + box.width) > window.innerWidth ||
        (box.top + box.height) > window.innerHeight
    );
}

/**
 * Subscribes a callback to touch move event on an element
 * @param {Function} moveLeft The on move left callback
 * @param {Function} moveRight The on move right callback
 * @param {Element} [el=document] Optional element. Default is the document
 * @return {Function} A function to unsubscribe from the event
 */
export function onTouchMove({moveLeft, moveRight, el = document}) {
    let touchStartX = NaN;
    let touchEndX = NaN;
    const touchIndex = 0;

    /**
     * Handles touch start event on the element
     * @param {Event} e The event object
     * @return {void}
     */
    function onTouchStart(e) {
        const touch = e.touches[touchIndex];
        touchStartX = touch.clientX;
    }

    /**
     * Handles touch move event on the element
     * @param {Event} e The event object
     * @return {void}
     */
    function onTouchMove(e) {
        const touch = e.touches[touchIndex];
        touchEndX = touch.clientX;
    }

    /**
     * Handles touch end event on the element
     * @param {Event} e The event object
     * @return {void}
     */
    function onTouchEnd(e) {
        if (!window.isNaN(touchStartX) && !window.isNaN(touchEndX)) {
            const data = { e, touchStartX, touchEndX };
            if (touchStartX > touchEndX) {
                moveLeft(data);
            } else {
                moveRight(data);
            }
        }

        window.dataLayer.push({
            'event': 'SP_SWIPE',
            'gtm.element': el,
        });

        touchStartX = NaN;
        touchEndX = NaN;
    }

    el.addEventListener('touchstart', onTouchStart);
    el.addEventListener('touchmove', onTouchMove);
    el.addEventListener('touchend', onTouchEnd);

    return function unsubscribe() {
        el.removeEventListener('touchstart', onTouchStart);
        el.removeEventListener('touchmove', onTouchMove);
        el.removeEventListener('touchend', onTouchEnd);
    };
}

/**
 * Find first scrollable parent
 * @param {Object} e dom object
 * @return {Object} scrollable parent
 */
export function getScrollParent(e) {
    let parent = window;
    let element = e;
    if (element !== window) {
        for (; element && element !== document.body; ) {
            const style = window.getComputedStyle(element) || {}, overflowY = style.overflowY;
            if (['auto', 'scroll'].includes(overflowY)) {
                parent = element;
                break;
            }
            element = element.parentElement;
        }
    }

    return parent;
}

/**
 * Calculate scroll top of specific element
 * @param {Object} element dom object
 * @return {Number} scroll top
 */
export function getScrollTop(element) {
    return element === window ? element.pageYOffset : element.scrollTop;
}
